import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Nav from './Nav'
import MenuIcon from '../assets/menu-icon-black.png'
import ExitIcon from '../assets/cancel-icon.png'
import GitHubIcon from '../assets/social/GitHub-Black.png'
import TwitterIcon from '../assets/social/Twitter-Black.png'
import LinkedInIcon from '../assets/social/LinkedIn-Black.png'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

const MenuButton = styled.img`
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
  margin: 36px 36px 0 0;
  height: 60px;
  width: 60px;

  :hover {
    opacity: 0.8 !important;
  }

  @media screen and (max-width: 900px) {
    margin: 22px 22px 0 0;
    height: 40px;
    width: 40px;
  }
`

const ExitButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  margin: 36px 36px 0 0;
  height: 60px;
  width: 60px;
  z-index: 1;

  @media screen and (max-width: 900px) {
    margin: 22px 22px 0 0;
    height: 40px;
    width: 40px;
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.bgColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100%;
  max-width: 100vw;
  width: 100%;
`

const FooterContainer = styled.div`
  padding: 0 4% 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    margin: 0 0 12px 0;
  }

  @media screen and (max-width: 1100px) {
    padding: 0 12% 0 0;
    font-size: 10px;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 900px) {
    padding-left: 6px;
  }
`

const Socials = styled.div`
  display: flex;
  flex-direction: row;
`

const GitHub = styled.img`
  height: 40px;
  width: auto;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    height: 32px;
  }
`

const Twitter = styled.img`
  height: 42px;
  width: auto;
  margin: 0 0 0 24px;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    height: 38px;
  }
`

const LinkedIn = styled.img`
  height: 38px;
  width: auto;
  margin: 3px 0 0 20px;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    height: 36px;
  }
`

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  componentDidMount() {
    window.document.body.style.backgroundColor = 'white'
  }

  openMenu() {
    this.setState({
      isMenuOpen: true,
    })
  }

  closeMenu() {
    this.setState({
      isMenuOpen: false,
    })
  }

  render() {
    const { children } = this.props
    const header = (
      <div>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            { name: 'description', content: "Cooper Kunz's personal website." },
          ]}
          title="cooperkunz.com"
        />
      </div>
    )
    if (this.state.isMenuOpen) {
      return (
        <Wrapper bgColor="white">
          {header}
          <ExitButton src={ExitIcon} onClick={this.closeMenu.bind(this)} />
          <Nav />
        </Wrapper>
      )
    } else {
      return (
        <div>
          {header}
          <MenuButton
            id="menu-btn"
            src={MenuIcon}
            onClick={this.openMenu.bind(this)}
          />
          {children}
          <FooterContainer>
            <p>
              Built by myself with ❤️ and{' '}
              <a href="https://www.gatsbyjs.org/">Gatsby</a>
            </p>
            <Socials>
              <a
                href="https://github.com/cooper-kunz"
                target="_blank"
                style={{ boxShadow: 'none' }}
              >
                <GitHub src={GitHubIcon} alt="GitHub Link" />
              </a>
              <a
                href="https://twitter.com/cooper_kunz"
                target="_blank"
                style={{ boxShadow: 'none' }}
              >
                <Twitter src={TwitterIcon} alt="Twitter Link" />
              </a>
              <a
                href="https://www.linkedin.com/in/cooper-kunz/"
                target="_blank"
                style={{ boxShadow: 'none' }}
              >
                <LinkedIn src={LinkedInIcon} alt="LinkedIn Link" />
              </a>
            </Socials>
          </FooterContainer>
        </div>
      )
    }
  }
}

export default Layout
