import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import GitHubIcon from '../assets/social/GitHub-White.png'
import TwitterIcon from '../assets/social/Twitter-White.png'
import LinkedInIcon from '../assets/social/LinkedIn-White.png'

const Menu = styled.div`
  border-radius: 16px;
  background-color: black;
  text-align: left;
  padding: 6% 8%;
  height: 78vh;
  width: 78vw;
  position: absolute;
  opacity: 1;

  h2 {
    width: auto;
    color: #535b61;
    opacity: 0.9;
    font-size: 42px;

    :hover {
      opacity: 1.2;
    }
  }

  @media screen and (max-width: 900px) {
    padding: 10%;
    height: 60vh;
    margin-top: -4vh;

    h2 {
      margin-top: 2.5rem;
      font-size: 28px;
    }
  }

  @media screen and (max-height: 600px) {
    margin-top: 6vh;
    height: 70vh;
  }
`

const Socials = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 76%;

  a {
    box-shadow: none;
  }

  @media screen and (max-width: 900px) {
    padding-left: 6px;
  }
`

const GitHub = styled.img`
  height: 50px;
  width: auto;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    height: 30px;
  }
`

const Twitter = styled.img`
  height: 80px;
  width: auto;
  margin: -14px 0px 0 12px;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    height: 50px;
    margin: -8px 12px 0 8px;
  }
`

const LinkedIn = styled.img`
  height: 48px;
  width: auto;
  margin: 0 0 0 12px;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    height: 32px;
    margin: 0;
    transform: translateX(-8px);
  }
`

const SmallTile = styled.div`
  border: 2px solid #fff;
  border-radius: 5px;
  position: absolute;
  top: 80%;
  left: ${props => props.left};
  height: 10vh;
  width: 10vh;
  animation: ${props => props.animation} ${props => props.speed} linear infinite;

  @media screen and (max-width: 900px) {
    left: 77.77%;
    top: 82%;
    height: 6vh;
    width: 6vh;
  }

  @media screen and (max-height: 600px) {
    margin-top: 0;
    top: 82%;
    left: 69.5%;
  }
`

const spin1 = keyframes`
  from {
    transform:rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
`

const spin2 = keyframes`
  from {
    transform:rotate(360deg);
  }

  to {
    transform:rotate(0deg);
  }
`

class Nav extends React.Component {
  render() {
    console.log(window.location.pathname)
    var currentPage = window.location.pathname

    var aboutLink = (
      <Link to="/" activeStyle={{ opacity: '0.5' }}>
        <h2 style={{ marginTop: 0 }}>About</h2>
      </Link>
    )

    var blogLink = (
      <Link to="/blog" activeStyle={{ opacity: '0.5' }}>
        <h2>Blog</h2>
      </Link>
    )

    var speakingLink = (
      <Link to="/speaking" activeStyle={{ opacity: '0.5' }}>
        <h2>Speaking</h2>
      </Link>
    )

    return (
      <Menu id="menu">
        {aboutLink}
        {blogLink}
        {speakingLink}
        <Socials>
          <a href="https://github.com/cooper-kunz" target="_blank">
            <GitHub src={GitHubIcon} alt="GitHub Link" />
          </a>
          <a href="https://twitter.com/cooper_kunz" target="_blank">
            <Twitter src={TwitterIcon} alt="Twitter Link" />
          </a>
          <a href="https://www.linkedin.com/in/cooper-kunz/" target="_blank">
            <LinkedIn src={LinkedInIcon} alt="LinkedIn Link" />
          </a>
        </Socials>
        <Link to="/">
          <SmallTile
            key={Math.random()}
            animation={spin1}
            speed="8s"
            top="80%"
            left="88%"
          />
          <SmallTile
            key={Math.random()}
            animation={spin2}
            speed="8s"
            top="80%"
            left="88%"
          />
        </Link>
      </Menu>
    )
  }
}

export default Nav
